<template>
  <cs-segment
    component="footer"
    class="cs-segment-footer">
      <cs-container class="is-fullwidth">
        <div class="navigation">
          <cs-links :data="$store.state.navigation.footer" />
        </div>
      </cs-container>
  </cs-segment>
</template>

<script>
export default {
  name: 'cs-segment-footer'
}
</script>

<style lang="sass">
.cs-segment-footer
  background-color: color('footer')
  margin-top: m(3)
  padding: m(3) 0
  +z-index('base')
  border-top: 2px solid color('primary')
  .navigation
    padding: 0 content-padding()
    .cs-links
      display: flex
      flex-direction: row
      flex-wrap: wrap
      .item
        margin-right: m(2)

+md
  .cs-segment-footer
    .navigation
      padding: 0 content-padding(md)

+sm
  .cs-segment-footer
    .navigation
      padding: 0 content-padding(sm)

+xs
  .cs-segment-footer
    .navigation
      padding: 0 content-padding(xs)

@media print
  .cs-segment-footer
    display: none
</style>