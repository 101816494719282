<template>
  <div class="cs-template-home">
    <cs-segment-header />
    <cs-page-home />
  </div>
</template>

<script>
import CsPageHome from '@/components/pages/Home'
import CsSegmentHeader from '@/components/segments/Header'

export default {
  name: 'cs-template-home',
  components: {
    CsPageHome,
    CsSegmentHeader
  },
  data () {
    return {}
  },
  mounted () {
    this.scrollMounted()
  },
  methods: {
    scrollMounted () {
      window.setTimeout(() => {
        this.$scroll.do('router/scroll-mounted')
      }, 500) // wait 500 until render is finished, @Safari
    }
  }
}
</script>

<style lang="sass">
.cs-template-home
  z-index: z-index('base')
</style>
