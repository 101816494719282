<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    :fullWidth="true"
    :speedFore="4"
    class="cs-page-home-welcome">
      <template #background>
        <cs-image
          :data="content.pageimage"
          size="home.welcome"
          class="is-hero" />
      </template>
      <template #foreground>
        <div class="logo">
          <router-link to="/" :title="$store.state.site.meta.title">
            <cs-svg name="logo" />
          </router-link>
        </div>
        <div class="canvas">
          <h1 class="service typo">
            {{ content.service1 }}<br />
            {{ content.service2 }}
          </h1>
          <cs-links
            :data="quicknav"
            :maxLevel="1"
            class="quicknav is-hidden-xs" />
          <div class="scrollbtn is-xs-only">
            <cs-svg
              @click.native="scrollButtonHandler($event)"
              :class="{ 'show': showButton }"
              name="arrow" />
          </div>
        </div>
      </template>
  </cs-parallax-segment>
</template>

<script>
import CsParallaxSegment from '@/components/elements/ParallaxSegment'

export default {
  name: 'cs-page-home-welcome',
  components: {
    CsParallaxSegment
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    }
  },
  data () {
    return {
      showButton: true
    }
  },
  computed: {
    quicknav () {
      var res = [], i = 0
      fn.each(this.$store.state.children, (data) => {
        if (i > 0) {
          res.push(data.head)
        }
        i++
      })
      return res
    }
  },
  methods: {
    scrollButtonHandler (Event) {
      Event.stopPropagation()
      this.$trigger('parallax-segment/goto', 1)
    },
    onWindowScrollHandler (Event) {
      if (Event.detail.top > 0) {
        this.showButton = false
      } else {
        this.showButton = true
      }
    }
  },
  events: {
    'window/scroll': 'onWindowScrollHandler'
  }
}
</script>

<style lang="sass">
.cs-page-home-welcome
  height: 100vh
  .cs-container
    display: flex
    flex-direction: column
    justify-content: space-between
    height: 100%
    .logo
      flex: 0 1 auto
      height: m(18)
      padding: content-padding(lg) content-padding(lg) 0 content-padding(lg)
      .cs-svg
        height: m(9)
        transition: all .5s
    .canvas
      flex: 1 0 auto
      display: flex
      flex-direction: column
      justify-content: flex-end
      padding: 0 content-padding(lg) 17vh 10vw
      transition: all .5s
      .service
        margin-left: -2px
        +typo(h1)
        font-size: 3.4rem
        line-height: 1.1
      .quicknav
        display: flex
        flex-wrap: wrap
        li
          padding-right: m(2)
          +typo(teaser)
          line-height: 1.8
          &:first-child
            padding-left: 0
          &:last-child
            padding-right: 0

+sm
  .cs-page-home-welcome
    .cs-container
      .logo
        padding: content-padding(sm) content-padding(sm) 0 content-padding(sm)
      .canvas
        padding-left: content-padding(sm)
        padding-bottom: content-padding(sm)
        .service
          font-size: 2.6rem

+xs
  .cs-page-home-welcome
    .cs-container
      .logo
        padding: content-padding(xs) content-padding(xs) 0 content-padding(xs)
      .canvas
        flex-direction: row
        justify-content: space-between
        padding: 0 content-padding(xs) content-padding(xs) content-padding(xs)
        .service
          display: flex
          flex-direction: column
          justify-content: flex-end
          font-size: 2.3rem
        .scrollbtn
          display: flex
          flex-direction: column
          justify-content: flex-end
          .cs-svg
            width: m(6)
            margin-bottom: 3px
            cursor: pointer
            opacity: 0
            transition: opacity .2s ease-in
            &.show
              opacity: 1
              transition: opacity .2s ease-in

+until(380px)
  .cs-page-home-welcome
    .cs-container
      .canvas
        .service
          font-size: 1.8rem

@media print
  .cs-page-home-welcome
    display: none

</style>