<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    :class="{ 'is-odd': !even, 'is-even': even }"
    class="cs-page-home-contact">
      <cs-row>
        <cs-text
          :data="text"
          component="cs-col"
          class="is-10 is-offset-1 is-12-sm is-no-offset-sm text" />
      </cs-row>
      <cs-row>
        <cs-col class="is-10 is-offset-1 is-12-sm is-no-offset-sm text">
          <cs-row>
            <cs-col class="is-4 name">
              <p>
                <strong>{{ $store.state.site.contact.name1 }}</strong><br />
                {{ $store.state.site.contact.name2 }}
              </p>
            </cs-col>
            <cs-col class="is-4 address">
              <p>
                {{ $store.state.site.contact.street }}<br />
                {{ $store.state.site.contact.town }}
              </p>
            </cs-col>
            <cs-col class="is-4 contact">
              <p>
                <cs-link :data="$store.state.site.contact.phone" /><br />
                <cs-link :data="$store.state.site.contact.email" />
              </p>
            </cs-col>
          </cs-row>
        </cs-col>
      </cs-row>
  </cs-parallax-segment>
</template>

<script>
import CsParallaxSegment from '@/components/elements/ParallaxSegment'

export default {
  name: 'cs-page-home-contact',
  components: {
    CsParallaxSegment
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    },
    even: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    text () {
      var res = []
      res.push({
        tag: 'h2',
        html: this.content.headline
      })
      if (fn.has(this.content, 'copy') && fn.isArray(this.content.copy)) {
        res = res.concat(this.content.copy)
      }
      return res
    }
  }
}
</script>

<style lang="sass">
.cs-page-home-contact
  padding-top: m(8) // padding-top because of scroll-anchor
  padding-bottom: m(8)
  .name,
  .address,
  .contact
    p
      +typo(teaser)
      strong
        +typo(teaserbold)

+sm
  .cs-page-home-contact
    padding-top: m(2)
    padding-bottom: m(2)

+xs
  .cs-page-home-contact
    .cs-row
      .cs-col
        &.name
          padding-bottom: 0
          > p
            margin-bottom: 0
        &.address
          padding-top: 0

</style>
