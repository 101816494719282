<template>
  <cs-segment
    component="header"
    class="cs-segment-header"
    ref="el">
      <cs-container class="is-fullwidth">
        <cs-navigation />
      </cs-container>
  </cs-segment>
</template>

<script>
import CsNavigation from '@/components/elements/Navigation'

export default {
  name: 'cs-segment-header',
  components: {
    CsNavigation
  },
  data () {
    return {
      headerTop: 0
    }
  },
  methods: {
    getHeaderHeight () {
      var dim = this.$dom.getDimensions(this.$refs.el)
      return dim.height
    },
    setHeaderPosition (top) {
      if (top !== this.headerTop) {
        this.headerTop = top
        this.$dom.setStyle(this.$refs.el, 'top', '-' + this.headerTop + 'px')
      }
    },
    setHeaderOnUserScroll (params) {

      // start position from top, where navbar begins to hide
      var height = this.getHeaderHeight()
      var start = height * 2
      var top = this.headerTop + params.diff
      if (start >= params.top || top < 0) {
        top = 0
      } else if (top > height) {
        top = height
      }
      this.setHeaderPosition(top)
    },
    onWindowScroll (Event) {
      this.setHeaderOnUserScroll(Event.detail)
    },
    onBeforeScroll (Event) {
      if (Event.detail.direction === 'up') {
         this.$scroll.registerOffset(this.$refs.el)
      } else {
         this.$scroll.registerOffset()
      }
    }
  },
  events: {
    'window/scroll': 'onWindowScroll',
    'scroll/beforeScroll': 'onBeforeScroll'
  }
}
</script>

<style lang="sass">
.cs-segment-header
  position: fixed
  top: 0
  right: 0
  width: 100px
  +z-index('header')
  .cs-container
    display: flex
    flex-direction: row
    justify-content: space-between
    align-items: center
    padding: 0 content-padding()
    height: m(8)
    transition: height .5s
    .cs-navigation
      height: 100%

+md
  .cs-segment-header
    .cs-container
      padding: 0 content-padding('md')

+sm
  .cs-segment-header
    .cs-container
      padding: 0 content-padding('sm')

+xs
  .cs-segment-header
    .cs-container
      padding: 0 content-padding('xs')
      .cs-svg
        height: m(2)

@media print
  .cs-segment-header
    display: none

</style>