<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    :class="{ 'is-odd': !even, 'is-even': even }"
    class="cs-page-home-default">
      <cs-sections
        :data="content.sections"
        :isSublevel="true"
        imgRadius="8px"
        cssClass="is-10 is-offset-1 is-12-sm is-no-offset-sm" />
  </cs-parallax-segment>
</template>

<script>
import CsParallaxSegment from '@/components/elements/ParallaxSegment'

export default {
  name: 'cs-page-home-default',
  components: {
    CsParallaxSegment
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    },
    even: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="sass">
.cs-page-home-default
  padding-top: m(8) // padding-top because of scroll-anchor
  padding-bottom: m(8)
  img
    margin-bottom: m(1)

+sm
  .cs-page-home-default
    padding-top: m(2)
    padding-bottom: m(2)

</style>
