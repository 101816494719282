<template>
  <div
    class="cs-navigation"
    :class="[ displayClass, altColorsClass ]"
    ref="nav">
      <nav
        class="nav-slider"
        :class="{ 'is-open' : sliderIsOpen, 'is-animated': sliderIsAnimated}"
        @click="clickSliderHandler($event)"
        v-touch:swipe.right="swipeSliderHandler">
          <div class="nav-viewbox">
            <cs-links
              v-if="$store.state.navigation.main.length > 0"
              :data="$store.state.navigation.main"
              :maxLevel="2"
              :dropdownStatus="dropdownStatus"
              :dropdownPosition="dropdownPosition"
              @click="clickHandler"
              class="nav-main"
              ref="main">
            </cs-links>
          </div>
      </nav>
      <div
        :class="{ 'is-open' : sliderIsOpen }"
        @click="clickMenuButtonHandler($event)"
        class="nav-button"
        ref="button">
          <div class="icon">
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
      </div>
      <cs-overlay
        @click="clickOverlayHandler($event)"
        :visible="sliderIsOpen" />
  </div>
</template>

<script>
import csOverlay from '@/components/elements/Overlay'
import { breakpoints } from '@/config/style.json'

export default {
  name: 'cs-navigation',
  components: {
    csOverlay
  },
  props: {

    // TODO: set dynamically
    dropdownPosition: {
      default: 'left'
    },

    // use alternative color settings
    altColors: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      sliderIsOpen: false,
      sliderIsAnimated: true,
      currentUri: null,
      dropdownStatus: {},
      toolbarTop: 0,
      init: false
    }
  },
  created () {
    this.dropdownStatus = this.getDropdownsStatus(this.$store.state.navigation.main)
  },
  mounted () {
    this.$nextTick(() => {
      this.init = true
    })
  },
  computed: {
    displayClass () {
      var res = []
      if (this.sliderIsOpen) {
        res.push('is-open')
      }
      return res.join(' ')
    },

    altColorsClass () {
      return this.altColors ? 'has-alt-colors' : null
    },

    // works only for 2 languages!
    languageLink () {
      var otherLang
      fn.each(this.$store.state.languages, (lang, key) => {
        if (key !== this.$store.state.lang) {
          otherLang = key
        }
      })
      return [{
        path: this.$store.state.page.head.translations[otherLang],
        title: fn.upper(otherLang)
      }]
    }
  },
  methods: {
    getDropdownsStatus (links) {
      var res = {}
      fn.each(links, (link) => {
        if (link.type === 'collection') {
          res[link.id] = this.$dom.isActiveUri(this.$route.path, link.value)
          if (link.children && link.children.length > 0) {
            res = Object.assign(res, this.getDropdownsStatus(link.children))
          }
        }
      })
      return res
    },
    openSlider () {
      this.sliderIsOpen = true
      this.$dom.fixBody()
    },
    closeSlider () {
      this.sliderIsOpen = false
      this.$dom.releaseBody()
    },
    toggleSlider () {
      if (this.sliderIsOpen) {
        this.closeSlider()
      } else {
        this.openSlider()
      }
    },
    openDropdown (link, closeOther) {
      if (closeOther) {
         fn.each(this.dropdownStatus, (dropdown, id) => {
          this.dropdownStatus[id] = (id === link.id)
        })
      } else {
        this.dropdownStatus[link.id] = true
      }
    },
    closeDropdown (link) {
      if (!link) {
        fn.each(this.dropdownStatus, (dropdown, id) => {
          this.dropdownStatus[id] = false
        })
      } else {
        this.dropdownStatus[link.id] = false
      }
    },
    toggleDropdown (link, closeOther) {
      if (this.dropdownStatus[link.id]) {
        this.closeDropdown(link)
      } else {
        this.openDropdown(link, closeOther)
      }
    },
    clickHandler (Event, link) {
      Event.stopPropagation()
      if (link.type === 'collection') {
        this.toggleDropdown(link)
      } else {
        this.closeSlider()
      }
    },
    clickMenuButtonHandler (Event) {
      Event.stopPropagation()
      this.toggleSlider()
    },
    clickSliderHandler (Event) {
      Event.stopPropagation()
    },
    swipeSliderHandler() { // test!
      this.closeSlider()
    },
    clickOverlayHandler (Event) {
      this.closeSlider()
    }
  }
}
</script>

<style lang="sass">
$nav-slider-bg-color:            color('white')
$nav-slider-border-color:        color('lighter')

$nav-slider-main-bg-color:       color('white')
$nav-slider-main-color:          color('dark')
$nav-slider-main-hover-color:    color('linkhover')
$nav-slider-main-active-color:   color('black')

// button
$nav-toolbar-height:             3.2rem
$nav-button-line:                3px
$nav-button-closed-color:        color('black') // closed status
$nav-button-open-color:          color('black') // open status

// alternative colors when altColor = true
$nav-button-closed-color-alt:    color('white')

.cs-navigation
  display: flex
  flex-direction: row
  justify-content: flex-end
  width: 100% // important, when navigation is fixed, put in smaller container when too wide
  &.on-init
    visibility: hidden
  .nav-slider
    visibility: hidden
    position: fixed
    top: 0
    right: 0
    height: 100%
    width: auto
    min-width: 300px
    max-width: 100%
    padding: $nav-toolbar-height 0 0 0
    background-color: $nav-slider-bg-color
    +z-index('slider', 10)
    transform: translateX(100%)
    +soft-shadow
    &.is-animated
      transition: transform .5s ease 0s, visibility 0s linear .5s
    &.is-open
      visibility: visible
      transform: translateX(0)
      &.is-animated
        transition: transform .5s ease 0s
    .nav-viewbox
      display: flex
      flex-direction: column
      height: 100%
      overflow-x: hidden
      overflow-y: scroll
      -webkit-overflow-scrolling: touch
      .item
        .cs-link
          display: flex
          align-items: center
          position: relative
          padding: m(.5) m(4)
          white-space: nowrap
          text-overflow: ellipsis
          cursor: pointer
          &:after // for additional icons (not :before, this is for dropdown-icon)
            flex: 0 1 auto
            margin-left: m(.5)
        &.has-dropdown
          >.cs-link
            display: flex
            flex-direction: row-reverse
            justify-content: flex-end
            align-items: center
            +icon('down')
            &:before
              margin-left: 4px
              font-size: .6em
          .level-2
            visibility: hidden
            height: 0
            .item
              .cs-link
                padding-left: m(6)
          &.is-open
            .level-2
              visibility: visible
              height: 100%
      .nav-main
        order: 1
        padding-top: m(2)
        padding-bottom: m(2)
        background-color: $nav-slider-main-bg-color
        border-top: 1px solid $nav-slider-border-color
        border-bottom: 1px solid $nav-slider-border-color
        .item
          .cs-link
            color: $nav-slider-main-color
            &:hover
              color: $nav-slider-main-hover-color
          >.cs-link
            &:before
              transform: rotate(-90deg)
              transition: transform .1s linear
          &.is-open
            >.cs-link
              &:before
                transform: translateY(2px) rotate(0deg)
          &.is-active
            >.cs-link
              color: $nav-slider-main-active-color
            &.is-open
              >.cs-link
                color: $nav-slider-main-color
  .nav-button
    display: flex
    align-items: center
    align-self: center
    cursor: pointer
    +z-index('slider', 20)
    .legend
      margin-right: m(1)
      margin-top: 3px
      +typo('h3')
      line-height: 1
      color: color('white')
    .icon
      position: relative
      display: inline-block
      width: $nav-button-line * 10
      height: $nav-button-line * 7
      span
        position: absolute
        display: block
        background-color: $nav-button-closed-color
        height: $nav-button-line
        width: 100%
        border-radius: 2px
        &:nth-child(1)
          top: 0
          transition: top .1s linear .2s, height 0s linear .2s, background-color .2s linear 0s
        &:nth-child(2)
          top: $nav-button-line * 3
          transition: transform .2s linear, background-color .2s linear 0s
        &:nth-child(3)
          top: $nav-button-line * 3
          transition: transform .2s linear, background-color .2s linear 0s
        &:nth-child(4)
          bottom: 0
          transition: bottom .1s linear .2s, height 0s linear .2s, background-color .2s linear 0s
    &.is-open
      .legend
        display: none
      .icon
        span
          background-color: $nav-button-open-color
          &:nth-child(1)
            height: 0
            top: $nav-button-line * 3
            transition: top .1s linear 0s, height 0s linear 0s
          &:nth-child(2)
            transform: rotate(45deg)
            transition: transform .2s linear
          &:nth-child(3)
            transform: rotate(-45deg)
            transition: transform .2s linear
          &:nth-child(4)
            height: 0
            bottom: $nav-button-line * 3
            transition: bottom .1s linear 0s, height 0s linear 0s
  .cs-overlay
    +z-index('slider', -10)

  // alternative colors
  &.has-alt-colors
    .nav-button
      .icon
        span
          background-color: $nav-button-closed-color-alt
      &.is-open
        .icon
          span
            background-color: $nav-button-open-color

+xs
  .cs-navigation
    .nav-slider
      min-width: 75%
    .nav-button
      .legend
        display: none
</style>