<template>
  <cs-segment class="cs-page-projects">
    <cs-container>
      <cs-sections
        :data="$store.state.page.content.sections"
        imgRadius="8px" />
    </cs-container>
    <cs-container>
      <cs-row class="is-multiline">
        <cs-col
          v-for="(project, key) in projects"
          :key="key"
          class="is-6 is-12-sm project">
            <cs-image
              :data="project.content.pageimage"
              size="projects.all"
              radius="8px" />
            <h3 class="text">{{ project.content.title }}</h3>
            <cs-text
              :data="project.content.copy"
              class="typo" />
        </cs-col>
      </cs-row>
    </cs-container>
  </cs-segment>
</template>

<script>
export default {
  name: 'cs-page-projects',
  data () {
    return {
      projects: []
    }
  },
  created () {
    this.$api.children('referenzen')
      .then(response => {
        this.projects = response.data.children
      })  
  }
}
</script>

<style lang="sass">
.cs-page-projects
  margin: m(16) 0 m(4) 0
  .cs-sections
    padding-bottom: m(3)
  .project
    padding-bottom: m(5)

@media print
  .cs-page-projects
    margin: 0
</style>
