<template>
  <cs-parallax-segment
    :hash="hash"
    :positionZ="positionZ"
    :class="{ 'is-odd': !even, 'is-even': even }"
    class="cs-page-home-projects">
      <cs-row>
        <cs-col class="is-10 is-offset-1 is-12-sm is-no-offset-sm text">
         <h2>{{ content.headline }}</h2>
        </cs-col>
      </cs-row>
      <cs-row v-if="content.copy">
        <cs-col class="is-10 is-offset-1 is-12-sm is-no-offset-sm text">
         <cs-text
          :data="content.copy"
          class="text" />
        </cs-col>
      </cs-row>
      <cs-row v-if="projects.length > 0">
        <cs-col class="is-10 is-offset-1 is-12-sm is-no-offset-sm">
          <cs-row class="is-multiline">
            <cs-col
              v-for="(project, key) in projects"
              :key="key"
              :class="cssClass(key)"
              class="project">
                <cs-image
                  :data="project.content.pageimage"
                  size="projects.home"
                  radius="8px" />
                <h3 class="typo">{{ project.content.title }}</h3>
            </cs-col>
          </cs-row>
        </cs-col>
      </cs-row>
      <cs-row v-if="projects.length > 0">
        <cs-col class="is-10 is-offset-1 is-12-sm is-no-offset-sm link">
          <cs-link :data="content.link[0].link" class="button" />
        </cs-col>
      </cs-row>
  </cs-parallax-segment>
</template>

<script>
import CsParallaxSegment from '@/components/elements/ParallaxSegment'

export default {
  name: 'cs-page-home-projects',
  components: {
    CsParallaxSegment
  },
  props: {
    hash: {
      type: String,
      default: null
    },
    positionZ: {
      type: Number,
      default: 0
    },
    content: {
      type: Object
    },
    even: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      projects: []
    }
  },
  methods: {
    cssClass (key) {
      log(this.projects.length)
      if (this.projects.length === 1) {
        return 'is-12'
      } else if (this.projects.length === 2) {
        return 'is-6'
      } else {
        return key === 0 ? 'is-12' : 'is-6'
      }
    }
  },
  created () {
    this.$api.children('referenzen', { limit: 3 })
      .then(response => {
        this.projects = response.data.children
      })
  }
}
</script>

<style lang="sass">
.cs-page-home-projects
  padding-top: m(8) // padding-top because of scroll-anchor
  padding-bottom: m(8)
  .project
    position: relative
    h3
      display: inline
      position: absolute
      left: 0
      max-width: 90%
      bottom: 30px
      padding: m(1) m(2)
      background-color: color(white, .7)
      +typo(regular)
      +ellipsis()
  .link
    display: flex
    justify-content: center
    margin-top: m(2)

+sm
  .cs-page-home-projects
    padding-top: m(2)
    padding-bottom: m(2)

</style>
