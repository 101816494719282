<template>
  <div class="cs-parallax-canvas" id="cs-parallax-canvas" ref="el">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'cs-parallax-canvas',
  data () {
    return {}
  },
  mounted () {
    this.$events.registerScrollContext(this.$refs.el)
    this.$scroll.registerContext(this.$refs.el)
  },
  destroyed () {
    this.$events.unregisterScrollContext(this.$refs.el)
    this.$scroll.unregisterContext()
  },
  methods: {
    updateHash (hash, isUserScrolled) {
      if (hash && this.$route.hash !== hash) {
        this.$router.replace({
          hash: '#' + hash,
          query: this.$route.query,
          params: { 'isUserScrolled': isUserScrolled }
        }).catch((err) => {})
      }
    },
    posTopHandler (Event) {
      this.updateHash(Event.detail.hash, true)
    },
    posBottomHandler (Event) {
      if (Event.detail.direction === 'down') {
        var key = this.$children.length - 1
        if(this.$children[key].hash === Event.detail.hash) {
          this.updateHash(Event.detail.hash, true)
        }
      }
    },
    gotoSegmentHandler (Event) {
      if(this.$children[Event.detail].hash) {
        this.updateHash(this.$children[Event.detail].hash, false)
      }
    }
  },
  events: {
    'parallax-segment/top': 'posTopHandler',
    'parallax-segment/bottom': 'posBottomHandler',
    'parallax-segment/goto': 'gotoSegmentHandler'
  }
}
</script>

<style lang="sass">
.cs-parallax-canvas
  height: 100vh
  overflow-x: hidden
  overflow-y: scroll
  perspective: 100px
  +z-index('base')
  -webkit-overflow-scrolling: touch

body
  overflow: hidden
</style>
