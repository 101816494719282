<template>
  <div class="cs-template-default">
    <cs-segment-header />
    <cs-segment>
      <cs-container class="is-fullwidth">
        <div class="logo">
          <router-link to="/" :title="$store.state.site.meta.title">
            <cs-svg name="logo" />
          </router-link>
        </div>
      </cs-container>
    </cs-segment>
    <component
      :is="currentPage"
      :key="currentKey"
      class="cs-page">
    </component>
    <cs-segment-footer />
  </div>
</template>

<script>
import CsSegmentHeader from '@/components/segments/Header'
import CsSegmentFooter from '@/components/segments/Footer'

export default {
  name: 'cs-template-default',
  components: {
    CsSegmentHeader,
    CsSegmentFooter
  },
  computed: {
    currentPage () {
      return this.$router.getPage()
    },
    currentKey () {
      return 'cs-page-' + this.$router.getBlueprint()
    }
  },
}
</script>

<style lang="sass">
.cs-template-default
  .cs-container
    .logo
      padding: content-padding(lg) content-padding(lg) 0 content-padding(lg)
      .cs-svg
        height: m(9)
        transition: all .5s

+sm
  .cs-template-default
    .cs-container
      .logo
        padding: content-padding(sm) content-padding(sm) 0 content-padding(sm)
        .cs-svg
          height: m(8)

+xs
  .cs-template-default
    .cs-container
      .logo
        padding: content-padding(xs) content-padding(xs) 0 content-padding(xs)
        .cs-svg
          height: m(7)

</style>
